<template>
	<v-container 
		fluid 
		class="dashboard"
	>
		<v-row>
			<v-col			
				cols="12"
				md="4"
			>
				<div class="highlight-box">
					<div class="initials" style="background-color: #ef6262; color: #a83d3b">
						<span>IN</span>
					</div>
					<div class="highlight-details ">
						<v-icon>mdi-arrow-collapse-down</v-icon>
						<span>{{tLabel('viaggi_arrivo')}}</span>
						<span class="count">{{ tripsIn.length }}</span>
					</div>
				</div>
			</v-col>
			<v-col			
				cols="12"
				md="4"
			>
				<div class="highlight-box">
					<div class="initials" style="background-color: #007be5; color: #00448f">
						<span>OUT</span>
					</div>
					<div class="highlight-details ">
						<v-icon>mdi-arrow-collapse-up</v-icon>
						<span>{{tLabel('viaggi_partenza')}}</span>
						<span class="count">{{ tripsOut.length }}</span>
					</div>
				</div>
			</v-col>

			<v-col			
				cols="12"
				md="4"
			>
				<div class="highlight-box">
					<div class="initials" style="background-color: #20d077; color: #038d4a">
						<span>CV</span>
					</div>
					<div class="highlight-details ">
						<v-icon>mdi-train</v-icon>
						<span>Convogli</span>
						<span class="count">{{ tripsIn.length }}</span>
					</div>
				</div>
			</v-col>
			<!-- <v-col			
				cols="12"
				md="6"
				xl="3"
			>
				<div class="highlight-box">
					<div class="initials" style="background-color: #f9c851; color: #b58c2b">
						<span>MN</span>
					</div>
					<div class="highlight-details ">
						<v-icon>mdi-tools</v-icon>
						<span>In manovra</span>
						<span class="count">{{ trainsOnRail }}</span>
					</div>
				</div>
			</v-col> -->
		</v-row>
		<v-row>
			<v-col
				cols="12"
				md="6"
			>
				<train-journeys-panel 
					:title="tLabel('viaggi_arrivo_oggi')" 
					:trainsData="tripsIn"
					iconDescription="mdi-arrow-collapse-up"
				/>
			</v-col>
			<v-col
				cols="12"
				md="6"
			>
				<train-journeys-panel 
					:title="tLabel('viaggi_partenza_oggi')" 
					:trainsData="tripsOut"
					iconDescription="mdi-arrow-collapse-down"
				/> 
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="2">
				<v-combobox
					v-model="selectedPark"
					:items="parks"
					:label="tLabel('Parco selezionato')"
					item-text="codice"
				/>
			</v-col>						
		</v-row>
		<v-row no-gutters>
			<v-col cols="12">
				<calLegend :details="convoyStates" />
			</v-col>
		</v-row>
		<v-row no-gutters>
			<v-col cols="12">
				<railsCalendar
					v-model="currDate" 
					:parkName="parkName" 
				/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import trainJourneysPanel from '../components/misc/TrainJourneysPanel';
import railsCalendar from '../components/rails/RailsCalendar';
import calLegend from '../components/misc/Legend';
import multiParkManagementMixins from '../mixins/MultiParkManagementMixins';
import moment from 'moment';

export default {
	name: "dashboard",
	components: {
		trainJourneysPanel,
		railsCalendar,
		calLegend
	},
	data() {
		return {			
			tripsIn: [],
			tripsOut: [],
			startDate: moment().format('YYYY-MM-DD'),
			currDate: moment().format('YYYY-MM-DD'),
		}
	},
	mixins: [multiParkManagementMixins],
	async created() {
		// try{
		// 	await this.$wsHubRegister('dashboardRT', data => console.log(data));
		// 	await await this.$wsHubRegister('testRT', data => console.log(data));
		// } catch(e) {
		// 	console.log(e);
		// }		
	},
	async destroyed() {
		// try{
		// 	await this.$wsHubUnRegister('dashboardRT');
		// 	await this.$wsHubUnRegister('testRT');
		// } catch(e) {
		// 	console.log(e);
		// }
	},
	async mounted(){		
		try {
			await this.setTrips();	
		} catch(e) {
			console.log(e);
		}
	},
	computed: {
		trainsOnRail() {
			const now = moment();
			let count = 0;
			this._.forEach(this.tripsIn, trip => {
				const eta = moment(trip.eta);
				const etd = moment(trip.etd);
				if(now.isSameOrAfter(eta) && now.isSameOrBefore(etd)){
					count++;
				}
			});
			return count;
		},
		convoyStates() {
			return this.$config.rails.convoyStates;
		}
	},
	methods: {
		async setTrips(){
			try{
				const tsDateFrom = this.$utilities.dates.getTsFromDateFormat(this.currDate);
				const tsDateTo = this.$utilities.dates.getTsFromDateFormat(moment(this.currDate).add(1, 'days'));
				this.plannedConvoys = {};
				const trips = await this.$api.railData.getViaggiByDateRange(tsDateFrom, tsDateTo);
				this._.forEach(trips, t => {
					if(t.tipoViaggio && t.tipoViaggio.toLowerCase() === "arrivo"){
						this.tripsIn.push(t);
					} else {
						this.tripsOut.push(t);
					}
				});
			} catch(e) {
				console.log(e);
			}
		},
	},
	watch: {
	}
}
</script>

<style scoped>

</style>