<template>
    <v-card style="height: 100%">
        <v-card-title>
           {{ title }} 
        </v-card-title>
        <v-card-text>
            <ul class='task-list'>
            <li v-for="train in trainsData" :key="train.id">
                <span class="task-name"  style="display:inline-table;width:60%;">
                    {{ viewNomeViaggio() ? train.nome : train.codice}}: {{ train.nodoPartenza.codice }} - {{ train.nodoArrivo.codice }}
                </span>
                <span style="text-align:right;font-weight:bold;display:inline-table;width:40%;">                    
                    {{ getTimeFromDate(train.eta) }} - {{ getTimeFromDate(train.etd) }}
                    &nbsp;
                    <v-icon>{{ iconDescription }}</v-icon>
                </span>                
            </li>
        </ul>
        </v-card-text>        
    </v-card>
</template>

<script>
import moment from 'moment';
import railsMixins from '../rails/mixins/railsMixins';

export default {
    name: "trainJourneysPanel",
    data() {
		return {			
			hours: 24
		}
	},
    props: {
        title: {
            type: String,
            default: 'Tasks'
        },
        trainsData: {
            type: Array,
            default: () => []
        },
        iconDescription: {
            type: String,
            default: 'pi pi-info'
        }
    },
    mixins: [railsMixins],
    methods: {
        getTimeFromTs(ts){
            return moment(+ts).format('HH:mm');
        },
        getTimeFromDate(strDate){
            if(!strDate) return null;
            return moment(strDate).format('HH:mm');
        },

    },

}
</script>

<style scoped>

</style>